@mixin ir {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

%ir {
  @include ir;
}

// Fonts
$regular-family: 'Metropolis-Regular';
$semi-family: 'Metropolis-SemiBold';
$bold-family: 'Metropolis-Bold';

// Foundation settings
$header-font-family: $bold-family, sans-serif;
$body-font-family: $regular-family, sans-serif;

$header-lineheight: 1.2;

$blockquote-color: #0a0a0a;
$blockquote-border: none;
$cite-font-size: 1.4rem;
$cite-color: #0a0a0a;

@font-face {
  font-family: 'Metropolis-Regular';
  src: url('../fonts/Metropolis-Regular.eot');
  src: local('☺'),
    url('../fonts/Metropolis-Regular.woff') format('woff'),
    url('../fonts/Metropolis-Regular.ttf') format('truetype'),
    url('../fonts/Metropolis-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family:'Metropolis-Medium';
  src: url('../fonts/Metropolis-Medium.eot');
  src: url('../fonts/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Metropolis-Medium.woff2') format('woff2'),
    url('../fonts/Metropolis-Medium.woff') format('woff'),
    url('../fonts/Metropolis-Medium.ttf') format('truetype'),
    url('../fonts/Metropolis-Medium.otf') format('opentype'),
    url('../fonts/Metropolis-Medium.svg#Metropolis-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family: 'Metropolis-Bold';
  src: url('../fonts/Metropolis-Bold.eot');
  src: local('☺'),
    url('../fonts/Metropolis-Bold.woff') format('woff'),
    url('../fonts/Metropolis-Bold.ttf') format('truetype'),
    url('../fonts/Metropolis-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.button {
  text-decoration: none;
  color: white;
  display: inline-block;
  border: 2px solid white;
  border-radius: 50px;
  padding: 1rem 2rem;
  transition: background ease 300ms, color ease 300ms;

  &:hover {
    background: white;
    color: $body-font-color;
  }

  &--dark {
    color: $body-font-color;
    border-color: $body-font-color;

    &:hover {
      background: $body-font-color;
      color: white;
    }
  }
}

.header {
  background: url(../img/misty-mountains.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
  color: white;

  .container {
    @extend %container;
    @extend %grid;
    @extend %align-center;

    height: 100%;
  }

  .app-logo {
    @extend %full-size;
    align-self: flex-start;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .app-name {
    @extend %ir;
    background: url(../img/tabs-logo.png) no-repeat;
    background-size: 100%;
    width: 180px;
    height: 63px;
  }

  .intro {
    @extend %full-size;

    @include breakpoint(large) {
      @include two-thirds-size;
    }

    .copy {
      @extend h1;
      margin-bottom: 2rem;
    }

    .button {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  .ad {
    @extend %shrink-size;
    align-self: flex-end;
    margin-bottom: 2rem;
    @include hide-for(medium down);

    .leaderboard {
      width: 768px;
      height: 90px;
    }
  }
}

.sentences {
  @extend %grid;
  @extend %container;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .sentence {
    @extend %full-size;
    @extend h2;
    text-align: center;

    @include breakpoint(large) {
      @include auto-size;
    }
  }
}

.description {
  margin-bottom: 2rem;

  @include breakpoint(large) {
    background: url(../img/laptop-screen.png) no-repeat;
    background-size: 50%;
    background-position: 0 50%;
    height: 500px;
  }

  @include breakpoint(xlarge) {
    background-size: 650px;
  }

  .container {
    @extend %grid;
    @extend %container;
    @extend %align-right;
    @extend %align-middle;

    @include breakpoint(large) {
      height: 100%;
    }
  }

  .content {
    @extend %full-size;

    @include breakpoint(large) {
      @include half-size;
    }
  }

  h3 {
    @extend h5;
  }
}

.quotes {
  margin: 4rem 0;

  .container {
    @extend %grid;
    @extend %container;
  }

  .content {
    @extend %full-size;
    background: url(../img/leaves.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }

  .frame {
    background: #a6d2d3;
    margin: 8rem 4rem;
    padding: 1rem;

    @include breakpoint(large) {
      padding: 2rem 4rem;
    }
  }

  .heading {
    text-align: center;
  }

  .quote {
    @extend h2;
    font-family: $regular-family;
    font-weight: normal;
  }
}

.laptop {
  margin-bottom: 4rem;

  .container {
    @extend %grid;
    @extend %container;
  }

  .content {
    @extend %full-size;
  }

  .img {
    @extend %ir;
    background: url(../img/laptop.png) no-repeat;
    background-position: center;
    background-size: 50%;
    width: 100%;
    height: auto;
    padding-bottom: 30%;
  }
}

.follow-review {
  margin-bottom: 4rem;

  .container {
    @extend %grid;
    @extend %container;
    @extend %align-center;
  }

  .box {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include breakpoint(medium down) {
      @include full-size;
    }

    @include breakpoint(large) {
      @include xy-cell(50%, $gutter-type: padding);
    }

    .heading {
      color: white;
      margin-bottom: 1rem;
    }

    &--follow {
      background: black;

      .button {
        margin: 0 1rem 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--review {
      background: #ef9c82;

      .heading {
        color: $body-font-color;
      }
    }
  }
}

.footer {
  background: darken(white, 5%);
  border-top: 1px solid darken(white, 10%);
  padding: 2rem;

  .container {
    @extend %grid;
    @extend %container;
  }

  .nav {
    @extend %full-size;
    text-align: center;
    margin-bottom: 1rem;

    a {
      display: inline-block;
      margin: 0 1rem;
    }
  }

  .info-items {
    @extend %full-size;
    @extend %grid;
    @extend %align-center;
  }

  .info-item {
    @extend %shrink-size;
    text-align: center;

    @include breakpoint(large) {
      margin: 0 2rem;
    }
  }
}

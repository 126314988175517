%container {
  @include xy-grid-container;
}

%align-center {
  @include flex-align($x: center);
}

%align-middle {
  @include flex-align($y: middle);
}

%align-bottom {
  @include flex-align(null, bottom);
}

@mixin align-right {
  @include flex-align($x: right);
}

%align-right {
  @include align-right;
}

@mixin align-spaced {
  @include flex-align($x: spaced);
}

%align-spaced {
  @include align-spaced;
}

@mixin align-justify {
  @include flex-align($x: justify);
}

%align-justify {
  @include align-justify;
}

%grid {
  @include xy-grid;
}

%grid-y {
  @include xy-grid(vertical);
}

@mixin auto-size {
  @include xy-cell($size: auto);
}

%auto-size {
  @include auto-size;
}

@mixin shrink-size {
  @include xy-cell($size: shrink);
}

%shrink-size {
  @include shrink-size;
}

@mixin full-size {
  @include xy-cell(100%);
}

%full-size {
  @include full-size;
}

@mixin half-size {
  @include xy-cell($size: 50%, $gutters: 20);
}

%half-size {
  @include half-size;
}

@mixin two-thirds-size {
  @include xy-cell(66%);
}

%two-thirds-size {
  @include two-thirds-size;
}

@mixin one-third-size {
  @include xy-cell(33%);
}

%one-third-size {
  @include one-third-size;
}

@mixin quarter-size {
  @include xy-cell(25%);
}

%quarter-size {
  @include quarter-size;
}

%gutters {
  @include xy-gutters;
}

@mixin gutterless {
  @include xy-gutters(0);
}

%gutterless {
  @include gutterless;
}

%nested {
  @include xy-gutters($negative: true);
}
